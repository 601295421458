import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCube, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import 'swiper/css/autoplay';
import { ImageLoaderProps } from 'next/image';
import { ContentfulImage } from '../../components/Contentful';

import employeesData from 'utils/getEmployees';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const employees = employeesData.map((employee) => ({
    url: employee.image,
    name: employee.name,
    role: employee.role,
}));

const myLoader = ({ src }: ImageLoaderProps) => src;

const SwiperComponent = () => {
    return (
        <div className="relative flex-inline mobile:max-w-[80vw] md:max-w-[35vw] sm:max-h-screen justify-center items-center">
            <Swiper
                modules={[Navigation, Pagination, EffectCube, Autoplay]}
                effect="cube"
                loop={true}
                autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                navigation={{
                    nextEl: '.swiper-button-next-custom',
                    prevEl: '.swiper-button-prev-custom',
                }}
                pagination={{
                    el: '.custom-pagination',
                    clickable: true,
                    renderBullet: (index: any, className: any) => {
                        return `<span class="${className} w-3 h-3 rounded-full mx-1 cursor-pointer transition-all duration-300"></span>`;
                    },
                }}
                spaceBetween={50}
                slidesPerView={1}
                cubeEffect={{
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                }}
            >
                {employees.map((image, index) => (
                    <SwiperSlide key={index} className="relative flex flex-col items-center">
                        <div className="flex">
                            <ContentfulImage
                                alt=""
                                className="flex object-cover m-auto w-fit"
                                width={4000}
                                height={4000}
                                image={image.url}
                                loader={myLoader}
                                priority={index === 0}
                            />
                        </div>

                        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 w-[85%] p-1 backdrop-blur bg-gray-700/25 flex items-center justify-center flex-col">
                            <p className="mobile:text-[4vw] sm:text-[3vw] md:text-[1.2vw] font-semibold text-white">
                                {image.name}
                            </p>
                            <p className="mobile:text-[4vw] sm:text-[3vw] md:text-[1.2vw] font-semibold text-custom-button-2">
                                {image.role}
                            </p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Custom Pagination Container */}
            <div className="custom-pagination justify-center flex py-4"></div>

            {/* Left arrow */}
            <div className="">
                <button
                    className="mobile:invisible md:visible swiper-button-prev-custom absolute z-10 transform -translate-y-[800%] -translate-x-[-20%] bg-custom-button bg-opacity-30 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75"
                >
                    <IoIosArrowBack />
                </button>
            </div>

            {/* Right arrow */}
            <div className="">
                <button
                    className="mobile:invisible md:visible swiper-button-next-custom absolute right-0 z-10 transform -translate-y-[800%] -translate-x-[20%] bg-custom-button bg-opacity-30 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75"
                >
                    <IoIosArrowForward />
                </button>
            </div>
        </div>
    );
};

export default SwiperComponent;
