import { gsap } from "gsap";

import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classNames from "classnames";
import { useGSAP } from '@gsap/react';

import Footer from "@components/Footer";
import Button from "@components/Button";

import blogPostFields from "utils/getBlogPosts";
import * as Icons from "../icons/index";

import Image from "@components/Image";
import SEO from "@components/SEO";
import Script from "next/script";
import CustomerCard from "@components/CustomerCard";
import { FaArrowRight } from "react-icons/fa";
import BlogCard from "@components/BlogCard";
import Topbar from "@components/Topbar/Topbar";
import IconGrid from "@components/IconGrid";
import Swiper from "@components/Swiper";
import SwiperComponent from "@components/Swiper";
import MobileSwiperComponent from "@components/Swiper/swipermobile";
import AnimatedText from "@components/AnimatedText";
import Link from "next/link";
import lock from "../public/images/aws-icons/lock-outeline.png"
import { IoIosArrowForward, IoIosArrowRoundForward } from "react-icons/io";
import Form from "@components/Email";
import HomeForm from "@components/Email/homeContact";
import PulsingIcon from "@components/PulsingLock";

const titleClassesWrap = "text-3xl font-thin text-custom-text";
export const reactivePadding = "mobile:pt-6 mobile:pb-10 sm:pb-[5vw] sm:pt-[5vw] p-[5vw] md:px-[15vh] max-w-screen"
const gridStyle =
  "aspect-square bg-custom-white flex items-center justify-center transition-all duration-300 hover:shadow-sm hover:shadow-black hover:scale-105";

export const Label = (props: {
  title: string;
  className?: string;
  titleColorClass?: string;
  backgroundColorClass?: string;
  borderColorClass?: string;
  children?: React.ReactNode;
}) => {
  const {
    title,
    className,
    titleColorClass = "text-custom-text",
    backgroundColorClass = "bg-custom-bg",
    borderColorClass = "border-custom-button",
    children
  } = props;

  return (
    <Content>
      <div className={classNames(className)}>
        <h3
          className={classNames(
            `w-fit py-[1vh] px-3 
            mobile:mb-[2vh]
            sm:text-md 
            md:text-md
            md:px-4 
            md:mb-6 
            lg:mb-8
            lg:text-[1vw]`,
            titleColorClass,
            backgroundColorClass,
            borderColorClass,
          )}
        >
          {title}
        </h3>
      </div>
    </Content>
  );
};

export const SectionWithLabel = (props: {
  title: string;
  className?: string;
  children: ReactNode;
  titleAlignment?: "left" | "right";
  titleColorClass?: string;
  backgroundColorClass?: string;
  borderColorClass?: string;
}) => {
  const {
    title,
    children,
    titleAlignment = "left",
    className,
    titleColorClass = "text-custom-text",
    backgroundColorClass = "bg-custom-bg",
    borderColorClass = "border-custom-button",
  } = props;

  return (
    <Content>
      <div className={classNames("relative w-full md:py-12 lg:py-16", className)}>
        <TextWrap>
          <h3
            className={classNames(
              "text-lg sm:text-xl md:text-2xl w-fit py-2 px-3 md:px-4 mobile:mb-[1vh] md:mb-6 lg:mb-8 underline",
              titleColorClass,
              backgroundColorClass,
              borderColorClass,
              {
                "ml-auto": titleAlignment === "right",
              }
            )}
          >
            {title}
          </h3>
        </TextWrap>

        {children}
      </div>
    </Content>
  );
};

export const Content = (
  props: PropsWithChildren<{ className?: string; fullWidth?: boolean }>
) => {
  const { children, className, fullWidth = false } = props;

  return (
    <div className="w-full max-w-screen">
      <section
        className={classNames(className, {
          "w-full": !fullWidth,
        })}
      >
        {children}
      </section>
    </div>
  );
};


function App() {
  const container = useRef(null);
  const circle = useRef(null);

  useGSAP(() => {
    // use selectors...
    gsap.to(".box", { rotation: "+=360", duration: 3 });

    // or refs...
    gsap.to(circle.current, { rotation: "-=360", duration: 3 });
  },
    { scope: container }
  ); // <-- scope for selector text (optional)

  return (
    <div className="App">
      <div ref={container} className="container">
        <div className="box gradient-blue">selector</div>
        <div className="circle gradient-green" ref={circle}>
          Ref
        </div>
      </div>
      <div className="box gradient-blue">selector</div>
    </div>
  );
}

const EmailSignup = () => {
  return (
    <Script id="mailer-light">
      {`
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '691954');
      `}
    </Script>
  );
};

const Column = (props: PropsWithChildren<{ className?: string }>) => {
  const { children, className } = props;

  return (
    <div
      className={classNames(
        "flex flex-col items-stretch w-full md:flex-row",
        className
      )}
    >
      {children}
    </div>
  );
};

const iconsLeft = [Icons.left1, Icons.left2, Icons.left3];
const iconsRight = [Icons.right3, Icons.right2, Icons.right1];
const icons = [
  Icons.a1,
  Icons.a2,
  Icons.a3,
  Icons.a4,
  Icons.a5,
  Icons.a6,
  Icons.a7,
  Icons.a8,
  Icons.a9,
  //
  Icons.a10,
  Icons.a11,
  Icons.a12,
  Icons.a13,
  Icons.a14,
  Icons.a15,
  Icons.a16,
  Icons.a21,
  Icons.a18,
  //
  Icons.a19,
  Icons.a20,
  Icons.a21,
  Icons.a22,
  Icons.a23,
  Icons.a24,
  Icons.a6,
  Icons.a7,
  Icons.a2,
  //
  Icons.a12,
  Icons.a2,
  Icons.a14,
  Icons.a5,
  Icons.a8,
  Icons.a19,
  Icons.a16,
  Icons.a1,
  Icons.a5,
];

const hoverIcons = {
  a1: Icons.a1_hover,
  a2: Icons.a2_hover,
  a3: Icons.a3_hover,
  a4: Icons.a4_hover,
  a5: Icons.a5_hover,
  a6: Icons.a6_hover,
  a7: Icons.a7_hover,
  a8: Icons.a8_hover,
  a9: Icons.a9_hover,
  a10: Icons.a10_hover,
  a11: Icons.a11_hover,
  a12: Icons.a12_hover,
  a13: Icons.a13_hover,
  a14: Icons.a14_hover,
  a15: Icons.a15_hover,
  a16: Icons.a16_hover,
  a18: Icons.a18_hover,
  a19: Icons.a19_hover,
  a20: Icons.a20_hover,
  a21: Icons.a21_hover,
  a22: Icons.a22_hover,
  a23: Icons.a23_hover,
  a24: Icons.a24_hover,
};

export const TextWrap = (props: PropsWithChildren<{ className?: string }>) => {
  const { children, className } = props;
  return (
    <div className={classNames(className, "mobile:px-[0vh] mobile:py-[0vh] sm:py-[1vh] sm:px-0 md:px-0 md:py-[1vh]")} >
      {children}
    </div >
  )
}

export const ParagraphText = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <TextWrap>
      <p className={classNames(props.className, `text-pretty
                mobile:text-left
                mobile:text-[5vw]  
                sm:text-[2.5vw]
                md:text-start 
                md:px-0
                md:text-[1.5vw]
                lg:text-[3vw]
                leading-tight
                lg:font-bold`)}>
        {props.children}
      </p>
    </TextWrap>
  )
}

export function Home() {
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth >= 500 && window.innerWidth <= 985); // 1024px corresponds to 'lg'
      setIsMobileScreen(window.innerWidth <= 500);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <div className="flex flex-col mt-[6vh] md:mt-[15vh] sm:items-center relative">
        {/* TODO: remove topebar comment */}
        <Topbar></Topbar>
        <EmailSignup></EmailSignup>

        <SEO
          title="AWS Specialister - molntjänster, AI och automatisering"
          description="Vi är ett konsultbolag specialiserat på molntjänster, AI och automatisering, med fokus på AWS.."
        />

        {/* Title */}
        <Content className="md:h-screen mobile:mb-[15vh] mb-[0vh] sm:mb-0">
          <div className={`${reactivePadding} flex md:gap-12  sm:flex-col md:flex-row h-full justify-between `}>
            {/* Left Section (Text and Icons) */}
            <div className="flex flex-grow flex-col w-fit
              mobile:gap-5
              mobile:items-start 
              mobile:justify-center
              md:gap-0  
              md:items-start 
              md:justify-start 
            ">
              {/* Title */}
              <TextWrap className="">
                <div className="flex flex-col leading-tight 
                  mobile:text-[9vw]
                  mobile:justify-center 
                  mobile:items-center
                  md:justify-start
                  md:items-start
                  md:text-[4.5vw]
                ">
                  {/* Row 1 */}
                  <div className="flex items-center mobile:justify-start sm:justify-center w-full">
                    <h1 className="whitespace-pre font-thin">aws partner för </h1>
                    <Image
                      className="inline-block sm:w-[1em] sm:h-[1em] mobile:w-[1.5em] mobile:h-[1em]"
                      alt="AWS Logo"
                      src={Icons.titleAWSLogo}
                    />
                  </div>

                  {/* Row 2 */}
                  <div className="flex items-center mobile:justify-start justify-center w-full">
                    <h1 className="whitespace-pre mr-2 font-thin">säkra </h1>
                    <Image
                      className="inline-block md:h-[0.7em]
                      mobile:h-[0.9em]
                      sm:w-[0.8em] sm:h-[0.7em]"
                      alt="Lock Icon"
                      src={Icons.titleLock}
                    />
                    <h1 className="whitespace-pre ml-2 font-thin"> skalbara</h1>
                  </div>

                  {/* Row 3 */}
                  <div className="flex items-center mobile:justify-start sm:justify-center w-full">
                    <Image
                      className="inline-block
                      mobile:w-[1em] mobile:h-[1em]
                      sm:w-[0.8em] sm:h-[0.7em]"
                      alt="Cloud Icon"
                      src={Icons.titleCloud}
                    />
                    <h1 className="whitespace-pre font-thin"> molnlösningar</h1>
                  </div>
                </div>
              </TextWrap>

              {/* Mobile image */}
              <Image
                alt=""
                src="/images/main.jpeg"
                className="sm:hidden block object-cover object-center mobile:mt-4"
                width={4000}
                height={4000}
              />

              {/* Description */}
              <ParagraphText className="lg:text-[1.4rem] sm:text-[2vw] leading-tight mobile:text-[3vh] gap-0 mobile:mb-[0vh]">Vi hjälper er att bygga moderna applikationer med den senaste molntekniken.</ParagraphText>

              {/* Button */}
              <Button
                icon={<FaArrowRight />}
                className="w-fit 
                bg-custom-button
                mobile:h-[8vh] 
                sm:mt-[6vh]
                mt-[2vh]
                md:h-[9vh]  "
                label="Jobba med en aws specialist"
                onClick={(e) => {
                  e.preventDefault();
                  window.open("/contact", "_self");
                  // gsap.to(window, {
                  //   duration: 0,
                  //   scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                  // });
                }}
              />
            </div>

            {/* Right Section (Image and Badge) */}
            <div className="flex max-w-[40vw]">
              {/* Main Image */}
              <div className="">
                <Image
                  alt=""
                  src="/images/main.jpeg"
                  className="hidden md:block object-cover object-center"
                  width={4000}
                  height={4000}
                />
              </div>
            </div>
          </div >
        </Content >

        <div id="SYFTE"></div>
        <Content className={`md:px-[15vh] px-[2vh] max-w-screen`}>
          <Label
            backgroundColorClass="bg-custom-bg-tint"
            titleColorClass="text-custom-button-text"
            title="En ny form av säkerhet">
          </Label>
        </Content>

        <Content className={`sm:pb-[5vw] md:px-[15vh] px-[3vh] max-w-screen mobile:pb-0 mobile:w-full md:w-[72%] mb-[10vh] mx-auto flex justify-center lg:min-w-[1037px]`}>

          <div className="flex flex-col lg:gap-20">

              {/* Text */}
              <h6 className="mobile:mt-[3vh] md:text-[3vw] text-[6vw] leading-tight text-center">
                Våra ledord - tillit, enkelhet och samarbete - genomsyrar allt
                vi gör. Genom att leverera säkerhet som är lättillgänglig och
                robust, arbetar vi tillsammans med dig för att bygga långsiktig
                trygghet och tillväxt för din verksamhet.
              </h6>

              {/* Icons mid - TODO */}
              <div className="flex mobile:flex-col md:flex-row mobile:justify-center mobile:items-center md:justify-between md:items-center">


                {/* Icons left */}
                <div className="mobile:hidden sm:flex flex-row items-center gap-[1.5vw] mobile:invisible md:visible shrink">
                  <Image className="lg:w-[5vw] md:w-[3.5vw] shrink" alt="" src={iconsLeft[0]} />
                  <Image className="lg:w-[5.5vw] md:w-[4vw] shrink" alt="" src={iconsLeft[1]} />
                  <Image className="lg:w-[6vw] md:w-[4.5vw] shrink" alt="" src={iconsLeft[2]} />
                </div>

                {/* Lock Icon Centered */}
                <div className="
                  flex
                  justify-center 
                  shrink
                  items-center 
                  sm:p-[1vw]
                  mobile:mt-10
                  sm:m-0
                ">
                  <PulsingIcon />
                </div>

                {/* Icons right */}
                <div className="mobile:hidden sm:flex mobile:gap-10 justify-start flex-row md:gap-[1.5vw] md:visible">
                  <Image className="lg:w-[6vw] md:w-[4.5vw] shrink" alt="" src={iconsRight[0]} />
                  <Image className="lg:w-[5.5vw] md:w-[4vw] shrink" alt="" src={iconsRight[1]} />
                  <Image className="lg:w-[5vw] md:w-[3.5vw] shrink" alt="" src={iconsRight[2]} />
                </div>

              </div>

              {/* Icons grid */}
              <div className="min-w-fit sm:scale-100">
                <IconGrid
                  columns={9}
                  Icons={Icons}
                  icons={icons}
                  hoverIcons={hoverIcons} 
                  invertColors={false}
                />              
              </div>
          </div>
        </Content>

        <div id="KONSULTER"></div>
        <div className={`${reactivePadding} flex flex-col md:flex-row w-full mobile:h-fit sm:min-h-screen justify-center items-center bg-custom-button`}>
          {/* Full-width background wrapper */}
          <Content>
            <div className="flex flex-col justify-between w-full gap-10
                md:flex-row  
                sm:pt-[5vh] 
                sm:pb-[5vh] 
                md:pt-0"
            >

              {/* Left Box (Text Content) */}
              <div className="flex flex-1 flex-col ">
                <Label
                  title="Konsulter"
                  backgroundColorClass="bg-custom-button-2-tint"
                  titleColorClass="text-custom-button-2"
                  className="mobile:mb-[3vh]">
                </Label>
                <div className="flex items-center">
                  <h6 className="flex text-white flex-col text-[6vw] sm:text-[7vw] md:text-[4vw] lg:text-[4vw] leading-tight mb-[3vh]">
                    <TextWrap>
                      <div className="flex">
                        <span className="">AWS-certifierade</span>
                      </div>

                      <div className="flex">
                        <span className="">säkerhetsspecialister</span>
                      </div>
                    </TextWrap>
                  </h6>
                </div>

                {/* Description & swiper */}
                <div className="flex justify-center items-center flex-col">
                  {isMidScreen ?
                    // For smaller screens
                    (
                      <div className="flex justify-center items-center flex-col gap-10">
                        <div className="flex flex-1">
                          <ParagraphText className="text-custom-white">
                            Våra konsulter är specialiserade på att bygga säkra molnlösningar. Som en del av Amazon Partner Network
                            använder vi den senaste tekniken och de bästa metoderna för
                            att hjälpa företag maximera värdet av sina molninvesteringar.
                          </ParagraphText>
                        </div>
                        <div className="flex flex-1">
                          <SwiperComponent />
                        </div>
                      </div>
                    )
                    :
                    // For Large screens
                    (<ParagraphText className="text-custom-white lg:text-[1.2rem] lg:w-3/4">
                      Våra konsulter är specialiserade på att bygga säkra molnlösningar. Som en del av Amazon Partner Network
                      använder vi den senaste tekniken och de bästa metoderna för
                      att hjälpa företag maximera värdet av sina molninvesteringar.
                    </ParagraphText>)
                  }
                </div>

                {/* Swiper on mobile */}
                {isMobileScreen && (
                  <div className="mt-10">
                    <MobileSwiperComponent />
                  </div>
                )}

                <TextWrap>
                  <div className="flex mt-10 md:mt-6 gap-4 h-14 mobile:justify-center mobile:items-center sm:justify-start sm:items-start">
                    <Button
                      className="h-14 lg:h-[9vh] bg-custom-button-2"
                      textColor="black"
                      label="Jobba med en aws specialist"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("/contact", "_self");
                        // gsap.to(window, {
                        //   duration: 0,
                        //   scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                        // });
                      }}
                    />
                  </div>
                </TextWrap>
              </div>

              {/* Right Box (box2) */}
              <div className="hidden md:flex flex-1 flex-shrink justify-end items-center">
                <SwiperComponent />
              </div>

            </div>
          </Content>
        </div>

        <div id="KUNDER"></div>
        <Content className={`${reactivePadding} bg-custom-white`}>
          <Label
            backgroundColorClass="bg-custom-bg-tint"
            titleColorClass="text-custom-button-text"
            title="Kunder"
          >
          </Label>
          <TextWrap>
            <h1 className="font-thin text-custom-text 
              mobile:text-[5vw] 
              mobile:mb-[2vh]

              sm:mt-[0vh]
              sm:mb-[0vh]
  
              md:text-[3vw]
              lg:mb-[5vh]">
              Upptäck våra kunders resor
            </h1>
          </TextWrap>

          <CustomerCard />
        </Content>

        <div id="OMOSS"></div>
        <div className="w-screen">
          <Content
            className={`${reactivePadding}`}
          >
            {/* Content */}
            <Label
              backgroundColorClass="bg-custom-bg-tint"
              titleColorClass="text-custom-button-text"
              title="Om oss">
            </Label>
            <TextWrap>
              <h1 className="font-thin text-custom-text 
              mobile:text-[5vw] 
              mobile:mb-[2vh]

              sm:mt-[0vh]
              sm:mb-[0vh]
  
              md:text-[3vw]
              lg:mb-[5vh]">
                Vi är AWS-säkerhet.
              </h1>
            </TextWrap>
            <div className="flex mobile:flex-col md:flex-row mobile:gap-[2vh] md:gap-10">

              <div className="flex basis-[60%]">
                <div className="relative w-full h-full m-auto shadow-lg">
                  <Image
                    alt=""
                    className="h-full w-full object-cover"
                    src="/images/persons/altogroup.jpg"
                    width={4032}
                    height={4032}
                  />
                </div>
              </div>

              {/* Text */}
              <div className="flex flex-col basis-1/2 mobile:gap-[2vh] sm:gap-0">
                {/* Text content */}
                <div className="flex flex-col gap-5">
                  <ParagraphText className="lg:text-custom-black lg:text-[1.3rem] lg:leading-7">
                    Altostruct grundades 2020 med en vision att skapa säkra och
                    skalbara molnlösningar för alla. Sedan dess har vi hjälpt
                    över 20 företag att migrera och utveckla robusta
                    molnlösningar anpassade till dagens höga krav på säkerhet
                    och flexibilitet.
                  </ParagraphText>

                  <ParagraphText className="lg:text-custom-black lg:text-[1.3rem] lg:leading-7">
                    Med serviceinriktning och fokus på nära samarbete är vi en
                    engagerad partner som strävar efter att skapa långsiktigt
                    värde och kvalitet i varje projekt.
                  </ParagraphText>
                </div>

                {/* Buttons */}
                <div className="flex flex-grow lg:gap-3 items-center  
                justify-between
                mobile:flex-row
                sm:mt-[3vh]
                mt-[4vh]
                md:justify-between
                lg:items-end
                ">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("/contact", "_self");
                      // gsap.to(window, {
                      //   duration: 0,
                      //   scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                      // });
                    }}
                    label="Kontakta oss"
                    className="h-14 px-5"
                  ></Button>
                </div>
              </div>
            </div>
          </Content>
        </div>

        <div id="NYHETER"></div>
        <div className="w-screen bg-custom-white">
          <Content
            className={`${reactivePadding}`}
          >
            <Label
              backgroundColorClass="bg-custom-bg-tint"
              titleColorClass="text-custom-button-text"
              title="Nyheter">
            </Label>

            <TextWrap>
              <h1 className="font-thin text-custom-text 
              mobile:text-[5vw] 
              mobile:mb-[2vh]

              sm:mt-[0vh]
              sm:mb-[0vh]
  
              md:text-[3vw]">
                Kolla in det senaste från oss.
              </h1>
            </TextWrap>

            {/* Content */}
            <div className="">
              <div className="flex justify-between">
                {/* TODO: text */}
                <span></span>
                <span> {blogPostFields.length}+ artiklar</span>
              </div>

              {/* Blogposts */}
              <div className="mt-5">
                <div className="mobile:text-md flex justify-end align-middle items-center">
                  Scrolla för mer
                  <IoIosArrowRoundForward />

                </div>
                <BlogCard />
              </div>
            </div>
          </Content>
        </div>

        <div id="KONTAKTAOSS"></div>
        <div className="w-screen">
          <Content
            className={`${reactivePadding}`}
          >
            <Label
              backgroundColorClass="bg-custom-bg-tint"
              titleColorClass="text-custom-button-text"
              title="Kontakt">
            </Label>
            <TextWrap>
              <h1 className="font-thin text-custom-text 
              mobile:text-[5vw] 
              mobile:mb-[2vh]

              sm:mt-[0vh]
              sm:mb-[0vh]
  
              md:text-[3vw]
              lg:mb-[3vh]">
                Hör gärna av dig!
              </h1>
            </TextWrap>
            {/* <h1 className={classNames(titleClassesWrap, "mb-5")}>test</h1> */}

            {/* Content */}
            <div className="flex 
            mobile:flex-col 
            sm:flex-row
            mobile:gap-[3vh]">
              <HomeForm className="w-1/2 flex-1" />
            </div>
          </Content>
        </div>

      </div >
      <Footer className={""}></Footer>
    </>
  );
}

export default Home;
