import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { ImageLoaderProps } from 'next/image';
import { ContentfulImage } from '../../components/Contentful';
import employeesData from 'utils/getEmployees';
import { useState } from 'react';

const employees = employeesData.map((employee) => ({
    url: employee.image,
    name: employee.name,
    role: employee.role,
}));

const myLoader = ({ src }: ImageLoaderProps) => src;

const SwiperComponent = ({ title }: any) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiper = useSwiper();

    const handleSlideChange = (swiper: any) => {
        setActiveIndex(swiper.realIndex);
    };

    return (
        <div className="relative mobile:max-w-[95vw] md:max-w-[35vw] sm:max-h-screen justify-center items-center">
            {title && <h2 className="text-center text-2xl font-bold mb-4">{title}</h2>}
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                loop={true}
                autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                onSlideChange={handleSlideChange}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                spaceBetween={50}
                slidesPerView={1}
            >
                {employees.map((image, index) => (
                    <SwiperSlide key={index} className="relative flex flex-col items-center">
                        <div className="flex">
                            <ContentfulImage
                                alt={image.name}
                                className="flex object-cover m-auto w-fit"
                                width={4000}
                                height={4000}
                                image={image.url}
                                loader={myLoader}
                                priority={index === 0}
                            />
                        </div>

                        <div className="absolute bottom-5 mobile:bottom-10 left-1/2 transform -translate-x-1/2 w-[85%] p-1 backdrop-blur bg-gray-700/25 flex items-center justify-center flex-col">
                            <p className="mobile:text-[4vw] sm:text-[3vw] md:text-[1.2vw] font-semibold text-white">
                                {image.name}
                            </p>
                            <p className="mobile:text-[4vw] sm:text-[3vw] md:text-[1.2vw] font-semibold text-custom-button-2">
                                {image.role}
                            </p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Custom Pagination Dots */}
            <div className="flex justify-center mt-4">
                {employees.map((_, index) => (
                    <button
                        key={index}
                        className={`w-2 h-2 rounded-full mx-1 cursor-pointer transition-colors duration-300 ${activeIndex === index ? 'bg-[#ED7100]' : 'bg-gray-300'
                            }`}
                        onClick={() => {
                            if (swiper) {
                                swiper.slideTo(index);
                            }
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default SwiperComponent;