import { useState, useEffect } from 'react';
import Image from 'next/image';
import classNames from 'classnames';

type Icon = {
    src: string;
    height: number;
    width: number;
};

type IconGridProps = {
    columns?: number;
    icons: Icon[]; // Change to Icon array
    fitContent?: boolean;
    invertColors?: boolean; 
    size?: number;
    hoverIcons?: Record<string, Icon>; // Store hover versions as Icon objects
    Icons: Record<string, Icon>; // Icons object containing all imported icons
};

const IconGrid = ({
    columns = 9,
    icons = [],
    fitContent = false,
    invertColors = false,
    size = 1,
    hoverIcons = {},
    Icons,
}: IconGridProps) => {
    const [visibleIcons, setVisibleIcons] = useState<Icon[]>(icons);
    const [hoverState, setHoverState] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        const updateVisibleIcons = () => {
            const width = window.innerWidth;

            if (width >= 1024) {
                setVisibleIcons(icons.slice(0, 36));
            } else if (width >= 768) {
                setVisibleIcons(icons.slice(0, 36));
            } else {
                setVisibleIcons(icons.slice(0, 0)); //CHANGE THIS TO SEE ITEMS IN MOBILE VIEW
            }
        };

        updateVisibleIcons(); 
        window.addEventListener('resize', updateVisibleIcons);

        return () => window.removeEventListener('resize', updateVisibleIcons);
    }, [icons]);

    const customLoader = ({ src }: { src: string }) => {
        return src;
    };

    return (
        <div
            className={classNames(
                "grid grid-cols-4 lg:gap-4 md:gap-2 sm:gap-2 gap-1",
                {
                    "grid-cols-4": fitContent && columns === 4,
                    "md:grid-cols-9": !fitContent,
                    "lg:grid-cols-9": !fitContent,
                }
            )}
        >
            {visibleIcons.map((icon, index) => {
                const iconKey = Object.keys(Icons).find(key => Icons[key].src === icon.src);
                const isHovered = iconKey && hoverState[index] && hoverIcons[iconKey];

                return (
                    <div
                        key={index}
                        className={classNames(
                            "flex items-center justify-center",
                            {
                                // Apply invert class only when not hovered
                                "invert": invertColors && !hoverState[index],
                            },
                            fitContent ? "w-fit" : "aspect-square bg-[#ecf5ff]"
                        )}
                        onMouseEnter={() => setHoverState((prev) => ({ ...prev, [index]: true }))}
                        onMouseLeave={() => setHoverState((prev) => ({ ...prev, [index]: false }))}
                    >
                        <div className="relative w-full h-full">
                            <Image
                                loader={customLoader}
                                src={isHovered ? (hoverIcons[iconKey]?.src || icon.src) : icon.src}
                                alt={`icon-${iconKey || index}`}
                                layout="fill"
                                className={classNames(
                                    "object-contain", {
                                         "scale-100": isHovered,  // No zoom when hovered
                                         "scale-[0.6]": !isHovered,  // Zoom out when not hovered
                                     }
                                 )}
                                priority={isHovered ? true : false}
                                // Important to ensure the image isn't re-rendered unexpectedly
                                key={isHovered ? hoverIcons[iconKey]?.src || icon.src : icon.src} 

                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default IconGrid;
