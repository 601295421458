import { useEffect, useState } from "react";
import lock from "../../public/images/aws-icons/lock-outeline.png";
import Image from "next/image"

const PulsingIcon = () => {
    const customLoader = ({ src }: { src: string }) => {
        return src;
    };

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <>
            {isClient && (
                <>
                    <style>
                        {`
                @keyframes pulseBackground {
                  0% {
                    transform: scale(1);
                    opacity: 1; /* Full opacity at the start */
                    background-color: rgba(0, 99, 255, 1); /* Dark blue */
                  }
                  50% {
                    transform: scale(1.4); /* Slightly bigger scale */
                    opacity: 0.7; /* Keep it partially visible during the pulse */
                    background-color: rgba(0, 99, 255, 0.6); /* Lighter blue */
                  }
                  100% {
                    transform: scale(1.4); /* Keep the scale at max size */
                    opacity: 0; /* Fade out after the pulse */
                  }
                }
  
                .pulsing-background {
                  animation: pulseBackground 2s ease-out infinite; /* Animation lasting 2 seconds */
                  animation-delay: 0.5s; /* Delay before starting the animation again */
                  animation-timing-function: ease-out;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%; /* Make background circular */
                  z-index: 0; /* Ensure it's behind the lock icon */
                  clip-path: circle(7vh at center); /* Ensure the pulsing starts outside the 7vh radius */
                }
  
                .lock-icon-wrapper {
                  position: relative;
                  width: 15vh; /* Size of the background circle */
                  height: 15vh; /* Size of the background circle */
                  display: flex;
                  justify-content: center; /* Center horizontally */
                  align-items: center; /* Center vertically */
                }
  
                .static-inner-circle {
                  position: absolute;
                  width: 12vh; /* Inner circle size (5vh radius) */
                  height: 12vh; /* Inner circle size (5vh radius) */
                  background-color: #012FA6; /* Updated color */
                  border-radius: 50%; /* Make it a circle */
                  z-index: 1; /* Ensure it's above the pulsing background */
                }
  
                .lock-icon {
                  position: absolute;
                  z-index: 2;
                  width: 6vh; /* Lock icon size in vh */
                  height: 6vh; /* Lock icon size in vh */
                  object-fit: contain; /* Ensure image fits inside the container */
                }
              `}
                    </style>

                    <div className="lock-icon-wrapper">
                        {/* Static inner circle */}
                        <div className="static-inner-circle"></div>

                        {/* Pulsing background circle */}
                        <div className="pulsing-background"></div>

                        {/* Lock Image */}
                        <Image
                            className="lock-icon"
                            alt="Lock Icon"
                            src={lock} // Import the image, and use the imported variable.
                            width={60}
                            height={60}
                            unoptimized={true}
                            loader={customLoader}

                        />
                    </div>
                </>
            )}
        </>
    );
};

export default PulsingIcon;