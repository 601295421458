import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../Button";
import classNames from "classnames";
import { TemplateParams, sendEmail } from "utils/sendEmail";
import { useRouter } from "next/router";


function HomeForm(
  props: PropsWithChildren<{
    tag?: string;
    image?: boolean;
    description?: ReactNode;
    title?: ReactNode;
    className?: string;
    buttonLabel?: string;
    type?: string;
    document?: boolean;
    onSubmit?: VoidFunction | (() => Promise<void>)
  }>
) {
  const {
    className,
    tag,
    onSubmit,
    description,
    image = true,
    buttonLabel = "Skicka",
    type,
  } = props;

  const SECURITY_ASSESMENT = type == "SECURITY_ASSESMENT";

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [newsletter, setNewsletter] = useState(true);
  const form = useRef<HTMLFormElement | null>(null);
  const router = useRouter();

  function isEmailValid(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return emailRegex.test(email);
  }

  const validateInput = (input: TemplateParams) => {
    const errors: string[] = [];

    if (!isEmailValid(input.email!)) {
      errors.push("Ogiltigt e-postformat.");
    }

    setIsDisabled(errors.length === 0);
    return errors;
  };

  const submit = async (event: any) => {
    event.preventDefault();
    // if no form exist in the ref something went very wrongs
    console.log("FORM REF:", form.current);
    console.log("FORM ELEMENTS:", form.current?.elements);



    const name = form.current?._name?.value ?? "-";
    console.log("NAME: ", name)
    console.log(form.current!.newsletter.value)
    const email: TemplateParams = {
      email: form.current!.email.value,
      name: tag
        ? tag + "::" + name + "\n NEWS_LETTER=" + (form.current!.newsletter.checked)
        : name + "\n NEWS_LETTER=" + (form.current!.newsletter.checked),
      newsletter: form.current!.newsletter.checked
    };

    const errors = validateInput(email);
    setValidationErrors(errors);

    await onSubmit?.();

    if (errors.length > 0) return;
    try {
      setIsSending(true);
      await sendEmail(email);

      if (SECURITY_ASSESMENT) {
        const query = new URLSearchParams()
        query.set("r", "/0b6e8502-c244-43ce-8abb-c00eb9b66780.pdf")
        query.set("m", "Laddar in pdf")
        await router.push("/events/reached-out?" + query.toString());
        return
      } else {
        await router.push("/events/reached-out");
        return
      }
      setIsSubmittedSuccessfully(true);
    } catch (error) {
      console.error(error);
      setIsSubmittedSuccessfully(false);
    } finally {
      setIsSending(false);
    }
  };

  const HandleAlert = (props: PropsWithChildren<{ severity: string }>) => {
    const { severity, children } = props;

    if (severity === "success") {
      return <div>{children}</div>;
    }

    if (severity === "failed") {
      return <div className="text-red-500">{children}</div>;
    }

    return <div>{children}</div>;
  };

  return (
    <div className="md:gap-24 md:w-6/12 w-full">
      {/* Title and text */}
      <div className="md:text-2xl md:align-top">
        <p className="">
          {description ??
            "Hej! Vi skulle gärna vilja prata med dig om ditt nästa projekt. Vi håller det simpelt, skriv din email nedan så återkommer vi snart."}
        </p>

        <form ref={form} onSubmit={submit} className="flex flex-col md:flex-col w-full">
          {/* Email Input and Submit Button (Side by side on Desktop) */}
          <div className="flex flex-col md:flex-row w-full md:gap-4 mt-4">
            <div className="flex flex-grow w-full">
              <input
                placeholder="Din mejladress"
                type="email"
                id="email"
                name="email"
                required
                className="h-14 w-full text-black md:text-lg rounded-none outline-none border pl-2 border-[#7d7d7d]"
              />
            </div>

            <div className="w-full mt-2 md:mt-0">
              <button
                type="submit"
                className="h-14 w-full md:w-auto md:px-16 bg-custom-button text-white border-transparent hover:bg-opacity-90"
                disabled={isSending}
              >
                {isSending ? "Skickar..." : buttonLabel}
              </button>
            </div>
          </div>

          {/* Newsletter Checkbox on a new row (on Desktop) */}
          <div className="flex gap-2 items-center w-full mt-4 md:mt-6">
            <input defaultChecked type="checkbox" id="newsletter" name="newsletter" />
            <label className="md:text-lg" htmlFor="newsletter">
              Ja, jag vill ha nyhetsbrev
            </label>
          </div>
        </form>

        {/* Validation Errors */}
        {!isSubmittedSuccessfully && validationErrors.length > 0 && (
          <div className="text-red-500">{validationErrors.join(", ")}</div>
        )}
      </div>
    </div>
  );




}

export default HomeForm;
